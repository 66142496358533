import salert from "sweetalert2"

const getCartProducts = () => {
  const storredItems = localStorage.getItem('cartItems')

  if (!storredItems) return []

  if (storredItems === '') {
    return []
  } else if (storredItems.split(',').length > 0) {
    return storredItems.split(',')
  } else {
    return []
  }
}

let cartProductIds = getCartProducts()

const addToCart = (productId) => {  
  if (productId) {
    cartProductIds.push(productId)
    localStorage.setItem('cartItems', cartProductIds.join(','))
    updateCounter()
  }
}

const linkToCart = productId => {
  if (productId) {
    addToCart(productId)
  }
  window.location.href = urlBuilder(cartProductIds)
}

const urlBuilder = productIds => {
  if (productIds.length > 0) {
    return Object.keys(groupById(productIds)).reduce((prev, curr, index) => {

      return prev += index === 0 ? `?product_ids[]=${curr}` : `&product_ids[]=${curr}`
  
    }, '/cart')
  }

  else return '/cart'
}

const removeItem = productId => {
  cartProductIds = cartProductIds.filter(id => id !== productId)

  if (cartProductIds.length > 0) {
    localStorage.setItem('cartItems', cartProductIds.join(','))
  } else {
    localStorage.removeItem('cartItems')
  }
  linkToCart()
}

const updateCounter = () => {
  if (cartProductIds.length > 0) {
    $('.jsCartCounter').text(cartProductIds.length)
    
  } else {
    $('.jsCartCounter').addClass('hidden')
  }
}

const softRemoveItem = productId => {
  const offsetIndex = cartProductIds.indexOf(productId)
  if (offsetIndex !== -1) {
    cartProductIds.splice(offsetIndex, 1)
    localStorage.setItem('cartItems', cartProductIds.join(','))
    updateCounter()
  }
}

const groupById = (productIds) => {
  return productIds.reduce((acc, cur, index) => {
    if (!acc[cur]) {
      acc[cur] = []
    }
    acc[cur].push(cur)

    return acc
  }, {})
}

const calcItemCounters = () => {
  const cartLength = cartProductIds.length;
  
  if (cartLength > 0) {
    const groupedIds = groupById(cartProductIds)
    Object.keys(groupedIds).map(k => $(`#product_${k}`).find('.jsCountInput').val(groupedIds[k].length))
  }
}

$(document).on('turbolinks:load', function () {
  updateCounter()

  if (window.location.pathname === '/catalog' || window.location.pathname === '/cart'){
    cartProductIds = getCartProducts()
  }

  if (window.location.pathname === '/cart') {
    calcItemCounters()
  }

  $('.jsInc').click(function(e) {
    const input = $(this).parent().find('.count')
    const currentVal = Number(input.val())

    input.val(currentVal + 1)
    addToCart(this.dataset.id)
  })

  $('body').on('click', '.jsDec', function(e) {
    const input = $(this).parent().find('.count')
    const currentVal = Number(input.val())

    if (currentVal < 2) {
      return
    } else {
      input.val(currentVal - 1)
      softRemoveItem(this.dataset.id)
    }
  })

  $(".jsLinkToCart").click(e => linkToCart())
  $(".jsAddToCart").click(e => linkToCart(e.target.dataset.id))
  $(".jsRemove").click(e => removeItem(e.target.dataset.id))

  $(".jsConfirmCart").click(e => {
    $('.createOrderPopup').addClass('visible')
  })

  const clearBasket = () => {
    localStorage.removeItem('cartItems')
  }

  $("#createOrderForm").on('submit', function(e) {
    e.preventDefault()
    const formData = $(this).serializeArray()

    let formattedData = formData.reduce((store, item) => {
      if (item.name === 'authenticity_token') {
        store[item.name] = item.value
      } else if (!store.user) {
        store.user = {
          [item.name]: item.value
        }
      } else {
        store.user = {
          ...store.user,
          [item.name]: item.value,
        }
      }
      return store
    },{})
    
    formattedData.product_ids = cartProductIds

    $.ajax({
      method: 'POST',
      url: 'create_order',
      data: formattedData,
      success: _ => {
        new salert({
          type: 'success',
          title: 'Спасибо за заявку!',
          text: 'В ближайшее время наш менеджер свяжется с вами.'
        }).then(() => {
          clearBasket()
          window.location.search = ''
        })
      },
      error: e => {
        new salert({
          type: 'error',
          title: 'Ошибка',
          text: "Произошла ошибка при отправке формы"
        })
      }
    })
  })
})